<template>
      <router-link to="/">
        <img class="harrodsLogo" src="@/assets/harrods.svg" placeholder="Harrods Logo">
      </router-link>
</template>

<script>
export default {
  name: 'HarrodsLogo',
  props: {
    msg: String
  }
}
</script>

<style lang="scss" scoped>
.harrodsLogo {
    width: 160px;
    height: 70px;
}
</style>

<template>
  <div class="home">
    <div class="container-fluid p-0">
			<GradientMaskedHero :title="documentToHtmlString(heroContent?.short_title)" :longTitle="documentToHtmlString(heroContent?.long_title)"
				:image="heroContent?.imageUrl" />
      <div class="content">
        <component v-for="(comp, index) in pageComponents" :key="index" :is="comp.type" v-bind="{ data: comp.data }">
				</component>
      </div>
    </div>
  </div>
</template>

<script>
import GradientMaskedHero from "@/components/Common/GradientMaskedHero.vue";
import { getContentForPage } from "@/api/contentful.api"
import { getComponents } from "@/helpers/cms.helper"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
  name: "VirtualTours",
  components: {
    GradientMaskedHero,
  },
  data() {
    return {
			content: [],
			pageComponents: []
		}
  },
	async created() {
		try {
			this.$global.isLoading = true;
			let response = await getContentForPage("partnershipHomepage", 'virtual-tours');
			this.content = response;
			this.pageComponents = getComponents(this.content);
		} catch (error) {
			console.error(error);
		}
		finally {
			this.$global.isLoading = false;
		}
	},
	mounted() { },
	updated() { },
	computed: {
		heroContent() {
			return this.content?.find(x => x.name === 'partnershipsTitle')?.data;
		}
	},
	watch: {},
	methods: { documentToHtmlString } ,
};
</script>
<style lang="scss" scoped>
@include media(">lg") {
.content {
  margin-left: 180px;
  margin-right: 180px;
}
}
</style>

<template>
	<div id="app">
		<Header />
		<loading :active.sync="$global.isLoading" :is-full-page="true">
			<template v-slot:default>
				<img class="rotate" src="@/assets/gold.svg" />
			</template>
		</loading>
		<div class="router-view">
			<router-view :key="$route.fullPath">
			</router-view>
		</div>
		<Footer />
	</div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
	name: 'App',
	components: {
		Header,
		Footer,
		Loading
	},
	computed: {
		isLoading() {
			return this.$isLoading
		}
	}
}
</script>

<style lang="scss">
@import '@/scss/base.scss';

body[style] {
	padding-right: 0px !important;
	margin-left: 0px !important;
}
</style>

<template>
  <header>
    <b-navbar
      fixed="top"
      toggleable="lg"
      type="light"
      class="bg-light h-nav-box py-lg-0"
    >
      <div class="h-sm-nav-box row d-lg-none m-0">
        <div class="col-3 text-align-left">
          <div class="d-block d-lg-none text-align-left h-ss-dp-btn-box">
            <b-navbar-toggle target="nav-collapse">
              <img
                src="@/assets/Header/hamburger.svg"
                class="h-ss-dp-btn nav-btn"
                v-bind:class="{ 'hide-icon': navIsExpanded }"
              />
            </b-navbar-toggle>
          </div>
        </div>

        <div class="col-6 d-block d-lg-none">
          <AppLink to="/">
            <a class="navbar-brand d-block d-lg-none justify-content-center">
              <img
                class="harrods-logo-small mt-1"
                src="@/assets/harrods.svg"
                placeholder="Harrods Logo"
              />
              <div class="logo-text">Partnerships</div>
            </a>
          </AppLink>
        </div>
        <div class="col-3 d-flex d-lg-none justify-content-end">
          <div>
            <button
              class="navbar-toggler nav-toggle d-block d-lg-none p-0 float-right"
              v-bind:class="{ 'hide-icon': !navIsExpanded }"
              type="button"
              data-toggle="collapse"
              v-on:click="closeNav"
            >
              <img src="@/assets/Header/close.svg" class="h-ss-dp-btn pb-2" />
            </button>
          </div>
        </div>
      </div>
      <b-collapse
        class="mr-0 w-100 h-100 navbar-nav-scroll"
        id="nav-collapse"
        v-model="navOpen"
        is-nav
      >
        <AppLink to="/" class="nav-logo">
          <a class="navbar-brand d-none d-lg-block">
            <img
              class="harrods-logo-large mt-2"
              src="@/assets/harrods.svg"
              placeholder="Harrods Logo"
            />
            <div class="logo-text">Partnerships</div>
          </a>
        </AppLink>

        <b-navbar-nav class="ml-auto h-nav-ul mr-0 mr-xl-5">
          <div
            v-for="menuItem in menuItems"
            :key="menuItem.fields.title"
            @mouseover="showDropdown(menuItem.fields.title)"
            @mouseleave="hideDropdown(menuItem.fields.title)"
            style="outline: none;"
          >
            <b-nav-item-dropdown
              v-if="menuItem.fields.navigation2Links"
              class="nav-item h-nav-li d-none d-lg-block p-0"
              :ref="menuItem.fields.title"
              :id="menuItem.fields.link"
            >
              <template slot="button-content">
                <AppLink :to="generateDeepLink(menuItem.fields.slug)">
                  <span class="nav-link h-nav-btn"
                    >{{ menuItem.fields.title }}</span
                  >
                </AppLink>
              </template>
              <DesktopMenu v-if="menuItem.fields.navigation2Links"
                v-bind:menuItems="menuItem.fields.navigation2Links"
              />
            </b-nav-item-dropdown>
            <b-nav-item v-else-if="menuItem.fields.slug.startsWith('http')" :href="menuItem.fields.slug" class="nav-item h-nav-li d-none d-lg-block p-0">
              <span class="nav-link h-nav-btn"
                    >{{ menuItem.fields.title }}</span
                  >
            </b-nav-item>
            <b-nav-item v-else :to="generateDeepLink(menuItem.fields.slug)" class="nav-item h-nav-li d-none d-lg-block p-0">
              <span class="nav-link h-nav-btn"
                    >{{ menuItem.fields.title }}</span
                  >
            </b-nav-item>
          </div>

          <MegaMenuMobile
            v-bind:menuItems="menuItems"
            class="d-block d-sm-block d-lg-none justify-content-center"
          />
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
</template>


<script>
import DesktopMenu from "@/components/Header/DesktopMenu.vue";
import MegaMenuMobile from "@/components/Header/MegaMenuMobile.vue";
import { getNavigation } from "@/api/contentful.api";
import { generateDeepLink } from "../helpers/cms.helper";
import AppLink from '@/components/Common/AppLink.vue'

export default {
  name: "Header",
  components: {
    DesktopMenu,
    MegaMenuMobile,
    AppLink
  },
  data() {
    return {
      navOpen: false,
      menuItems: []
    };
  },
  async created() {
		try {
			this.$global.isLoading = true;
      var navigation = await getNavigation();
      this.menuItems = navigation;
		} catch (error) {
			console.error(error);
		}
		finally {
			this.$global.isLoading = false;
		}
	},
  async mounted() {
  },
  computed: {
    navIsExpanded() {
      return this.navOpen || this.searchOpen;
    },
  },
  methods: {
    showDropdown(identifier) {
      var dropdown = this.$refs[identifier];
      if (dropdown && dropdown.length > 0)
      {
        dropdown[0].show();
      }
    },
    hideDropdown(identifier) {
      var dropdown = this.$refs[identifier];
      if (dropdown && dropdown.length > 0)
      {
        dropdown[0].hide();
      }
    },
    showAccountDropdown() {
      var dropdown = this.$refs["account-dropdown"];
      dropdown.show();
    },
    hideAccountDropdown() {
      var dropdown = this.$refs["account-dropdown"];
      dropdown.hide();
    },
    openNav() {
      this.navOpen = true;
    },
    closeNav() {
      this.navOpen = false;
    },
    generateDeepLink
  },
  watch: {
    $route() {
      this.navOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.hide-icon {
  display: none !important;
}
.nav-link:focus, .dropdown-toggle:focus, .nav-item:focus {
  outline: none !important;
}
.navbar-nav-scroll {
  max-height: 85vh;
}
.sign-out-img {
  margin-bottom: 3px;
  margin-left: 5px;
}
.White-Nav-BG {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  text-align: left;
  border-top: 2px solid #ab8e66 !important;
  padding: 5px 40px 25px 40px;
  width: 240px;
  @media screen and (min-width: 1200px) {
      transform: translateY(-5px);
    }
}
.nav-logo {
  margin-left: 0;
  @include media(">=lg") {
    margin-left: 42px;
  }
  @include media(">=xl") {
    margin-left: 180px;
  }
}
header {
  background-color: $harrods-white;
  font-size: 18px;
  text-align: center;
}

.openNav,
.openNav:focus {
  border: none !important;
  box-shadow: none !important;
  text-align: left;
}

.closeNav {
  display: none;
  &-opened {
    display: inline;
  }
}

.nav-toggle {
  margin-right: 1px;
  margin-top: 5px;
}

.h-nav-box {
  @media screen and (min-width: 992px) {
    border-top: 4px solid $harrods-gold;
    border-bottom: 6px solid $harrods-white;
    color: $dark-grey-1 !important;
    height: 120px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: $harrods-white !important;
  }

  @include media("<lg") {
    border-top: 4px solid $harrods-gold;
    border-bottom: 4px solid $harrods-white;
    color: $dark-grey-1 !important;
    min-height: 74px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: $harrods-white !important;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
}

.h-sm-nav-box {
  width: 100%;
  min-height: 52px;
  align-items: center;

  @include media(">=lg") {
    height: 120px;
  }
}

.h-ss-dp-btn-box {
  border-color: $harrods-white !important;
  outline: none;
  text-align: left;
}

.h-ss-dp-btn-nest-box {
  padding: 10px 14px 14px 30px;
}

.h-ss-dp-btn {
  cursor: pointer;
  width: 20px;
}

.h-nav-btn:hover {
  color: $harrods-gold !important;
}

.h-nav-btn {
  text-decoration: none;
  @media screen and (min-width: 992px) {
    padding-bottom: 40px;
    padding-top: 40px;
    flex-direction: row;
    color: #424242 !important;
    letter-spacing: 0.44px;
    text-align: center;
    font-size: 16px;
    line-height: 1.13;
  }

  @media screen and (max-width: 1199px) and (min-width: 992px) {
    padding-bottom: 17px;
    padding-top: 17px;
  }

  @media screen and (max-width: 991px) and (min-width: 500px) {
    border-bottom: 1px solid $light-grey-border-2;
    padding-bottom: 31px;
    padding-top: 31px;
    margin-left: 30px;
    margin-right: 30px;
    min-width: 140px;
    flex-direction: row;
    color: $mid-grey-3 !important;
    font-size: 18px;

    &:hover {
      border-top: 1px solid $light-grey-border-2;
      border-bottom: 1px solid $light-grey-border-2;
      color: $harrods-gold !important;
      background-color: transparent;
    }
  }
}

.h-nav-ul {
  margin-top: 6px;

  @media screen and (max-width: 1199px) and (min-width: 992px) {
    margin-top: 34px;
  }
}

.h-nav-li {
  @media screen and (min-width: 992px) {
    width: 117px;
  }

  // margin-top: 6px;

  @media screen and (max-width: 1199px) and (min-width: 992px) {
    // margin-top: 0px;
  }
}

.search-icon {
  @include media("<sm") {
    margin-left: 5px;
  }

  @include media(">sm") {
    margin-left: 30px;
  }

  @include media(">lg") {
    margin-top: 30px;
    margin-right: 40px;
  }

  @include media(">xl") {
    margin-right: 30px;
  }
}

.harrods-logo-large {
  width: 137px;
  height: 60px;
}

.harrods-logo-small {
  width: 85px;
  height: 37px !important;
}

.logo-text {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.5px;
  margin-top: 6px;
  line-height: 16px;
  color: #424242;
}
    .nav-btn {
    max-width: none;
    }
</style>

<template>
  <div aria-labelledby="navbarDropdown" class="menu-style White-Nav-BG">
    <div v-for="item in menuItems || null" :key="item.fields.slug" class="menu-item-right-border menu-item-style">
      <b-dropdown-item v-bind:to="{path: generateDeepLink(item.fields.slug)}" class="menu-item-link-style">{{item.fields.title}}</b-dropdown-item>
    </div>
  </div>
</template>

<script>
import { generateDeepLink } from '../../helpers/cms.helper';

export default {
  name: 'DesktopMenu',
  props: ['menuItems'],
  methods: {
    generateDeepLink
  }
}
</script>

<style lang="scss" scoped>
</style>

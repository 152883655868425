<template>
	<div>
		<div class="container-fluid p-0">
			<GradientMaskedHero title="Request your Media Pack"
								longTitle="Request your <br /> Media Pack"
								imageFileName="beauty-shot.jpg" />
		</div>
		<div class="container-fluid p-0 pb-5 mb-5 px-5 px-lg-0 fixed-width-lg">

			<form ref="form" class="form">
						<label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Title</label>
							<div class="select-box title">
								<select class="form-control pl-4" v-model.trim.lazy="$v.title.$model" v-bind:class="{ 'error-box' : $v.title.$dirty && (!$v.title.required)}">
									<optgroup>
										<option value="" disabled selected>Select</option>
										<option value="Dr">Dr.</option>
										<option value="Miss">Miss.</option>
										<option value="Mr">Mr.</option>
										<option value="Mrs">Mrs.</option>
										<option value="Ms">Ms.</option>
										<option value="Other">Other</option>
									</optgroup>
								</select>
								<span data-test="select-icon">
									<img src="@/assets/chevron-down.svg" class="chevron-icon" />
								</span>
								<div class="error-text mt-1" v-if="$v.title.$dirty">
									<p v-if="!$v.title.required">Please select a Title.</p>
								</div>
							</div>


					<label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">First Name</label>
					<input class="form-control input-field pl-4" type="text" name="FirstName" id="FirstName" v-model.trim.lazy="$v.firstName.$model" v-bind:class="{
            'error-box': $v.firstName.$dirty && (!$v.firstName.required || !$v.firstName.lettersOnly),
          }" />
					<div class="error-text" v-if="$v.firstName.$dirty">
						<p v-if="!$v.firstName.required || !$v.firstName.lettersOnly">Please enter a valid first name.</p>
					</div>

					<label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Last Name</label>
					<input class="form-control input-field pl-4" type="text" name="LastName" id="LastName" v-model.trim.lazy="$v.lastName.$model" v-bind:class="{
            'error-box': $v.lastName.$dirty && (!$v.lastName.required || !$v.lastName.lettersOnly),
          }" />
					<div class="error-text" v-if="$v.lastName.$dirty">
						<p v-if="!$v.lastName.required || !$v.lastName.lettersOnly">Please enter a valid last name.</p>
					</div>
					<label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Company Name</label>
					<input class="form-control input-field pl-4" type="text" name="Company" id="Company" v-model.trim.lazy="$v.companyName.$model" v-bind:class="{
            'error-box': $v.companyName.$dirty && !$v.companyName.required,
          }" />
					<div class="error-text" v-if="$v.companyName.$dirty">
						<p v-if="!$v.companyName.required">Please enter a company name.</p>
					</div>
					<label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Job Title</label>
					<input class="form-control input-field pl-4" type="text" name="job-title" id="job-title" v-model.trim.lazy="$v.jobTitle.$model" v-bind:class="{
            'error-box': $v.jobTitle.$dirty && !$v.jobTitle.required,
          }" />
					<div class="error-text" v-if="$v.jobTitle.$dirty">
						<p v-if="!$v.jobTitle.required">Please enter a job title.</p>
					</div>
					<label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Email Address</label>
					<input class="form-control input-field pl-4" type="email" name="email" id="email" v-model.trim.lazy="$v.email.$model" v-bind:class="{
            'error-box':
              $v.email.$dirty && (!$v.email.email || !$v.email.required),
          }" />
					<div class="error-text" v-if="$v.email.$dirty">
						<p v-if="!$v.email.email || !$v.email.required">
							Please enter a valid email address.
						</p>
					</div>
					<b-form-group class="mt-5 check-boxes" v-slot="{ ariaDescribedby }">
						<b-form-radio-group v-model="selectedOption"
											:options="options"
											:aria-describedby="ariaDescribedby"
											name="radio-inline"></b-form-radio-group>
					</b-form-group>
					<div id="recaptcha-main" class="g-recaptcha pt-3 pt-lg-0 mt-3 mt-lg-5" data-sitekey="6Lda1BAUAAAAABeemGvQod8rVNQQUSM2y9pFK_gS" data-callback="captchaCallback"></div>
					<Success v-if="sending" msg="Sending..." />
					<Success v-if="sent" msg="Message sent" />
					<Error v-if="errored" msg="An error occured." />
					<b-button variant="primary mt-5" @click="submit" :disabled="!isComplete()">Request Media Pack</b-button>
			</form>
		</div>
	</div>
</template>
<script>
	import { required, email } from "vuelidate/lib/validators";
	import Success from "@/components/Alerts/Success.vue";
	import Error from "@/components/Alerts/Error.vue";
	import axios from "axios";
	import qs from "qs";
	import GradientMaskedHero from "@/components/Common/GradientMaskedHero.vue";

	const lettersOnly = function (value) {
		return /^[-'a-zA-ZÀ-ž'\s]*$/.test(value);
	};

	export default {
		name: "RequestMediaPack",
		components: {
			Success,
			Error,
			GradientMaskedHero
		},
		props: ['preselectedOption'],
		data() {
			return {
				title: "",
				firstName: "",
				lastName: "",
				companyName: "",
				jobTitle: "",
				email: "",
				sending: false,
				sent: false,
				errored: false,
				captchaVerified: false,
				selectedOption: 'fashion',
				options: [
					{ text: 'Fashion', value: 'fashion' },
					{ text: 'Home & Tech', value: 'home-and-tech' },
					{ text: 'Fine Jewellery & Watches', value: 'fine-jewellery-and-watches' },
					{ text: 'Luxury Non-Retail', value: 'luxury-non-retail' },
					{ text: 'Beauty', value: 'beauty' },
					{ text: 'Corporate Partnerships', value: 'corporate-partnerships' },
				],
			};
		},
		async created() {
			this.$nextTick(function () {
				window.grecaptcha.render('recaptcha-main');
			})
		},
		mounted() {
			window.captchaCallback = this.captchaCallback;

			if (this.preselectedOption) {
				this.selectedOption = this.preselectedOption;
			}
		},
		updated() { },
		computed: {},
		watch: {},
		methods: {
			isComplete() {
				return this.title && this.firstName
					&& this.lastName && this.companyName && this.jobTitle
					&& this.email
					&& this.captchaVerified;
			},
			captchaCallback() {
				this.captchaVerified = true;
			},
			submit: function () {
				this.$v.$touch();
				if (!this.$v.$invalid) {
					const formData = new FormData(this.$refs["form"]); // reference to form element
					const jsonData = {}; // need to convert it before using not with XMLHttpRequest
					for (let [key, val] of formData.entries()) {
						Object.assign(jsonData, { [key]: val });
					}
					this.sending = true;
					this.sent = false;
					this.errored = false;
					const headers = {
						"Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
					};
					axios
						.post(
							process.env.VUE_APP_MAILINGLIST_SUBSCRIBE_URL,
							qs.stringify(jsonData),
							{ headers: headers }
						)
						.then((res) => {
							if (res.request.response === "ERROR: reCaptcha failed") {
								this.sent = false;
								this.sending = false;
								this.errored = true;
							} else {
								this.sent = true;
								this.sending = false;
								this.errored = false;
								this.$router.go();
							}
						});
				}
			},
		},
		validations: {
			title: {
				required,
			},
			companyName: {
				required,
			},
			jobTitle: {
				required,
			},
			firstName: {
				required,
				lettersOnly
			},
			lastName: {
				required,
				lettersOnly
			},
			email: {
				required,
				email: (val) => email(val.toLowerCase()),
			},
		},
	};
</script>
<style lang="scss">
	.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
		background-image: url("~@/assets/icon-general-checkmark.svg");
	}

	.custom-radio .custom-control-label::before {
		border-radius: 0;
	}

	.custom-control-input:checked ~ .custom-control-label::before {
		color: #FFFFFF;
		border-color: #AB8E66;
		background-color: #AB8E66;
	}

	.custom-radio .custom-control-input:active ~ .custom-control-label::before {
		color: #fff;
		background-color: #AB8E66;
	}

	/* the shadow; displayed while the element is in focus */
	.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
		box-shadow: none;
		border-color: #AB8E66;
	}

	.custom-control-inline {
		margin-right: 2rem;
	}

	.custom-control-label {
		padding-left: 7px;
		font-size: 14px;
	}

	.custom-control-label::after {
		background: 90%/90% 70% no-repeat;
		left: -1.57rem;

		&:active,
		&:focus {
			border-color: #AB8E66;
			background-color: #AB8E66;
		}
	}

	.custom-control-label::before,
	.custom-control-label::after {
		width: 15px;
		height: 15px;
		padding-right: 10px;
	}
</style>
<style lang="scss" scoped>
	select {
		height: 45px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		padding-right: 25px;
		font-size: 16px;
		position: relative;
		display: inline-block;
	}

	.blurb {
		margin-top: 15px;

		@include media(">lg") {
			margin-top: 30px;
		}
	}

	@include media(">lg") {
		.fixed-width-lg {
			max-width: 55%;
		}
	}

	.home {
		margin-left: 25px;
		margin-right: 25px;
		margin-bottom: 25px;

		@include media(">lg") {
			margin-top: 50px;
			margin-left: 200px;
			margin-right: 200px;
			margin-bottom: 200px;
		}
	}

	.card {
		border: none;
		margin-top: 30px;

		p {
			margin-top: 18px;
			height: 70px;

			@include media(">lg") {
				height: 72px;
			}
		}
	}

	h1 {
		font-family: "Miller Banner";
		font-weight: 300;
		font-style: italic;
		font-size: 26px;
		margin-top: 22px;
		color: #212121;
	}

	@include media("<sm") {
		.vue-grid-item {
			height: fit-content !important;
			transform: none !important;
			position: relative !important;
			width: 100% !important;
		}

		.vue-grid-layout {
			height: 100% !important;
		}
	}

	.chevron-icon {
		height: 10px;
		top: 19px;
		right: 17px;
		position: absolute;
		pointer-events: none;

		@include media(">=md") {
			top: 27px;
		}

		@include media(">=lg") {
			top: 30px;
		}
	}

	.check-boxes {
		display: flex !important;
	}

	.title {
		width: 130px;
		position: relative;
	}

	/*.custom-radio {
		margin: 10px !important;
	}*/
</style>

import httpClient from './content.httpClient';

const END_POINT_CONFIG = '/v2/search/content';
const END_POINT_NAVIGATION = '/v2/navigation/harrods-partnerships';

const getContent = (contentTypeCode, codes) =>
  httpClient.get(END_POINT_CONFIG, {
    params: {
      contentTypeCode,
      codes
    }
  });

const getNavigation = () =>
  httpClient.get(END_POINT_NAVIGATION);

export {
  getContent,
  getNavigation
}
<template>
  <footer>
    <div class="row col-12 d-flex justify-content-center p-0 m-0 pt-5">
      <HarrodsLogo class="harrodsLogo" />
    </div>
    <div class="row m-4">
        <div class="col-12 d-block d-md-flex text-center justify-content-center p-0">
            <span class="stay-upto-date float-lg-left mr-5 mt-md-5 mt-lg-4 d-none d-md-flex">Stay up to date with the latest Harrods Partnership news</span>
            <span class="stay-upto-date mt-3 d-block d-md-none">Stay up to date with the latest news</span>
            <b-button class="d-none d-md-block"
                      to="/mailing-list"
                      target="_blank"
                      variant="primary float-lg-left mt-4 mt-lg-0">Sign up for emails</b-button>
            <b-button 
                      class="w-100 d-block d-md-none"
                      to="/mailing-list"
                      target="_blank"
                      variant="primary float-lg-left mt-4 mt-lg-0">Sign up to emails</b-button>
        </div>
    </div>
      <div class="top-border"></div>
    <div class="row m-3 pt-5 pb-5 d-flex">
      <div class="col-12 text-left text-md-center">
        <a
          class="text-left text-md-center d-block d-md-inline p-0 mr-4 mb-3"
          href="https://www.harrods.com/en-gb/legal/accessibility?icid=footer_bottom_accessibility"
          target="_blank"
          >Accessibility</a
        >   
        <!--<a
          class="text-left text-lg-center d-block d-lg-inline p-0 m-0 mr-4 mb-3"
          href="javascript:Didomi.preferences.show()"
          >Cookie Preferences</a
        >-->
        <a
          class="text-left text-md-center d-block d-md-inline p-0 m-0 mr-4 mb-3"
          href="https://www.harrods.com/en-gb/legal/terms-and-conditions"
          target="_blank"
          >Terms & Conditions</a
        >
        <a
          class="text-left text-md-center d-block d-md-inline p-0 m-0 mr-3 mb-3"
          href="https://www.harrods.com/en-gb/legal/privacy-policy?icid=footer_bottom_privacy-policy"
          target="_blank"
          >Security &amp; Privacy Policy</a
        >
      </div>
    </div>
    <div class="row m-0 pb-5">
      <div class="col p-0 text-center">
        <span class="copyright"
          >© 2023 Harrods Limited. All Rights Reserved</span
        >
      </div>
    </div>
  </footer>
</template>

<script>
import HarrodsLogo from "@/components/HarrodsLogo.vue";

export default {
  name: "Footer",
  props: {
    msg: String,
  },
  components: {
    HarrodsLogo,
  },
};
</script>

<style lang="scss" scoped>
svg {
  fill: #fff;
  &:hover {
    fill: #ab8e66;
  }
}
.copyright {
  font-family: "Founders Grotesk";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: center;
  color: #9d9c9c;
}
.top-border {
    @include media("<md") {
        display: none;
    }

  border-top: 0.1rem solid rgba(238, 238, 238, 0.24);
  margin-left: 150px;
  margin-right: 150px;
}
.btn-primary {
  background: none;
  border-color: white;
  width: 146px;
}
.follow-us {
   @include media("<sm") {
       font-size: 10px;
       padding-top: 5px;
   }

  font-family: "Founders Grotesk";
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
}
.stay-upto-date {
  font-family: "Miller Banner";
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center !important;
  color: #ffffff;
}

footer {
  width: 100%;
  background-color: $harrods-medium-green;
  border-bottom: 3px solid #967c59;
  @include media("<sm") {
      padding: 15px;
  }
}

a {
  color: white !important;
  text-decoration: none;

  &:hover {
    color: #ab8e66 !important;
  }
}

.harrodsLogo {
  margin-top: 34px !important;
  margin-bottom: 45px !important;
}

.text {
  color: $harrods-white;
}
</style>

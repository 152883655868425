import cmsMappings from "./cms_mappings.json"

const generateDeepLink = (identifier) => {
    if (!identifier) {
        return "";
    }
    if (identifier.startsWith("http")) {
        return identifier;
    }

    identifier = identifier.replace("/harrods-partnerships/", "");

    if (identifier.startsWith("partnerships-")) {
        return `/${identifier.replace("partnerships-", "partnerships/")}`;
    }

    if (identifier.startsWith("case-studies-")) {
        return `/${identifier.replace("case-studies-", "case-studies/")}`;
    }

    if (!identifier.startsWith("/")) {
        identifier = `/${identifier}`;
    }

    return identifier;
}

const getComponents = (apiResponse) => {
    console.log(apiResponse);
    var mappedComponents = [];
    apiResponse.forEach(item => {
        if (item.name in cmsMappings) {
            mappedComponents.push({ type: cmsMappings[item.name], data: item.data })
        }
    });

    return mappedComponents;
}

export {
    getComponents,
    generateDeepLink
}
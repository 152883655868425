<template>
    <div>
        <div class="container-fluid p-0">
            <GradientMaskedHero title="Subscribe"
                                longTitle="Subscribe to <br /> Mailing List"
                                imageFileName="beauty-shot.jpg" />
        </div>
        <div class="container-fluid p-0 pb-5 mb-5 fixed-width-lg">
            <p class="text-center blurb">
                Subscribe to the Harrods Partnerships mailing list.<br />
                We will send you the occasional email, keeping you up to date with our
                latest campaign news and brand-marketing opportunities.
            </p>

            <form ref="form" class="form m-5">

                <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Company Name</label>
                <!--<input type="hidden" name="accName" value="harrodsmedia" />
    <input type="hidden" name="listName" value="2020 E-Marketing" />
    <input type="hidden" name="successUrl" value="NO-REDIRECT" />
    <input type="hidden" name="errorUrl" value="" />-->
                <input class="form-control input-field pl-4"
                       type="text"
                       name="Company"
                       id="Company"
                       v-model.trim.lazy="$v.companyName.$model"
                       v-bind:class="{
            'error-box': $v.companyName.$dirty && !$v.companyName.required,
          }" />
                <div class="error-text" v-if="$v.companyName.$dirty">
                    <p v-if="!$v.companyName.required">Please enter a company name.</p>
                </div>

                <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">First Name</label>
                <input class="form-control input-field pl-4"
                       type="text"
                       name="FirstName"
                       id="FirstName"
                       v-model.trim.lazy="$v.firstName.$model"
                       v-bind:class="{
            'error-box': $v.firstName.$dirty && (!$v.firstName.required || !$v.firstName.lettersOnly),
          }" />
                <div class="error-text" v-if="$v.firstName.$dirty">
                    <p v-if="(!$v.firstName.required || !$v.firstName.lettersOnly)">Please enter a valid first name.</p>
                </div>

                <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Last Name</label>
                <input class="form-control input-field pl-4"
                       type="text"
                       name="LastName"
                       id="LastName"
                       v-model.trim.lazy="$v.lastName.$model"
                       v-bind:class="{
            'error-box': $v.lastName.$dirty && (!$v.lastName.required || !$v.lastName.lettersOnly) ,
          }" />
                <div class="error-text" v-if="$v.lastName.$dirty">
                    <p v-if="!$v.lastName.required || !$v.lastName.lettersOnly">Please enter a valid last name.</p>
                </div>

                <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Email Address</label>
                <input class="form-control input-field pl-4"
                       type="email"
                       name="email"
                       id="email"
                       v-model.trim.lazy="$v.email.$model"
                       v-bind:class="{
            'error-box':
              $v.email.$dirty && (!$v.email.email || !$v.email.required),}" />
                <div class="error-text" v-if="$v.email.$dirty">
                    <p v-if="!$v.email.email || !$v.email.required">
                        Please enter a valid email address.
                    </p>
                </div>

                <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Category</label>
                <select name="Category"
                        id="Category"
                        v-model.trim.lazy="$v.category.$model"
                        v-bind:class="{
            'error-box': $v.category.$dirty && !$v.category.required,
          }"
                        class="form-control input-field">
                    <option value="" disabled selected>&nbsp;&nbsp;Category</option>
                    <option value="Beauty">&nbsp;&nbsp;Beauty</option>
                    <option value="Fashion">&nbsp;&nbsp;Fashion</option>
                    <option value="Fine Jewellery">&nbsp;&nbsp;Fine Jewellery</option>
                    <option value="Fine Watches">&nbsp;&nbsp;Fine Watches</option>
                    <option value="Home">&nbsp;&nbsp;Home</option>
                    <option value="Technology">&nbsp;&nbsp;Technology</option>
                    <option value="Food &amp; Beverage">
                        &nbsp;&nbsp;Food &amp; Beverage
                    </option>
                </select>
                <div class="error-text" v-if="$v.category.$dirty">
                    <p v-if="!$v.category.required">
                        Please select a category.
                    </p>
                </div>

                <b-form-checkbox name="event_confirm_policy" id="event_confirm_policy" class="pt-3" v-model="checked">
                    Please tick here to confirm you have read our
                    <a target="_new"
                       href="https://www.harrods.com/en-gb/legal/security-and-privacy">Privacy Policy</a>
                </b-form-checkbox>

                <div id="recaptcha-main" class="g-recaptcha pt-3 pt-lg-0 mt-3 mt-lg-5"
                                   data-sitekey="6Lda1BAUAAAAABeemGvQod8rVNQQUSM2y9pFK_gS" data-callback="captchaCallback">
                </div>
               

                <Success v-if="sending" msg="Sending..." />
                <Success v-if="sent" msg="Message sent" />
                <Error v-if="errored" msg="An error occured." />
                <b-button variant="primary mt-5" @click="submit" :disabled="!isComplete()">Subscribe</b-button>
                <router-link to="/">
                    <b-button variant="outline-secondary mt-3">Cancel</b-button>
                </router-link>
            </form>
        </div>
    </div>
</template>
<script>
	import { required, email } from "vuelidate/lib/validators";
	import Success from "@/components/Alerts/Success.vue";
	import Error from "@/components/Alerts/Error.vue";
	import axios from "axios";
	import qs from 'qs'
	import GradientMaskedHero from "@/components/Common/GradientMaskedHero.vue";

	const lettersOnly = function (value) {
		return /^[-'a-zA-Z�-�'\s]*$/.test(value);
    };


	export default {
		name: "MailingList",
		components: {
			Success,
			Error,
			GradientMaskedHero
		},
		data() {
			return {
				firstName: "",
				lastName: "",
				companyName: "",
                email: "",
                category: "",
				sending: false,
				sent: false,
				errored: false,
                checked: false,
                captchaVerified: false
			};
		},
		async created() {

			this.$nextTick(function () {
				window.grecaptcha.render('recaptcha-main');
			})
		},
        mounted() {
            window.captchaCallback = this.captchaCallback;
		},
        updated() { },
        watch: {
        },
        methods: {
            isComplete() {
                return this.companyName && this.firstName
                    && this.lastName && this.email
                    && this.category && this.checked
                    && this.captchaVerified;
            },
            captchaCallback() {
                this.captchaVerified = true;
            },
		submit: function () {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				const formData = new FormData(this.$refs["form"]); // reference to form element
				const jsonData = {}; // need to convert it before using not with XMLHttpRequest
				for (let [key, val] of formData.entries()) {
					Object.assign(jsonData, { [key]: val });
				}
				this.sending = true;
				this.sent = false;
				this.errored = false;
				const headers = {
					'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
				};
				axios
					.post(process.env.VUE_APP_MAILINGLIST_SUBSCRIBE_URL, qs.stringify(jsonData), { headers: headers })
					.then((res) => {
						console.log(res.request.response);
						if (res.request.response === 'ERROR: reCaptcha failed') {
							this.sent = false;
							this.sending = false;
							this.errored = true;
						}
						else {
							this.sent = true;
							this.sending = false;
                            this.errored = false;
							this.$router.go();
						}
					});
			}
        },
	},
	validations: {
		companyName: {
			required,
			},
		firstName: {
            required,
            lettersOnly
			},
		lastName: {
            required,
            lettersOnly
			},
		email: {
			required,
            email: (val) => email(val.toLowerCase()),
			},
		category: {
			required,
        },
	},
	};
</script>
<style>
.custom-control-label {
    padding-left: 7px;
    font-size: 14px;
}
.custom-control-label::before, .custom-control-label::after {
    width: 15px;
    height: 15px;
    padding-right: 10px;
}
</style>
<style lang="scss" scoped>
select {
  height: 45px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 25px;
  font-size: 16px;
}

.blurb {
  margin: 15px;

  @include media(">lg") {
    margin-top: 30px;
  }
}
@include media(">lg") {
  .fixed-width-lg {
    max-width: 35%;
  }
}
.home {
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  @include media(">lg") {
    margin-top: 50px;
    margin-left: 200px;
    margin-right: 200px;
    margin-bottom: 200px;
  }
}
.card {
  border: none;
  margin-top: 30px;

  p {
    margin-top: 18px;
    height: 70px;
    @include media(">lg") {
      height: 72px;
    }
  }
}
h1 {
  font-family: "Miller Banner";
  font-weight: 300;
  font-style: italic;
  font-size: 26px;
  margin-top: 22px;
  color: #212121;
}
@include media("<sm") {
  .vue-grid-item {
    height: fit-content !important;
    transform: none !important;
    position: relative !important;
    width: 100% !important;
  }

  .vue-grid-layout {
    height: 100% !important;
  }
}
	.g-recaptcha {
		@include media("<sm") {
			transform: scale(0.86);
			-webkit-transform: scale(0.86);
			transform-origin: 0 0;
			-webkit-transform-origin: 0 0;
		}
	}
</style>

<template>
    <div>
        <image-accordion v-if="this.data" :content="this.data.accordionItems" />
    </div>
</template>
  
<script>
import ImageAccordion from "@/components/Common/ImageAccordion.vue";

export default {
    name: "LatestTrends",
    props: ["data"],
    components: {
		ImageAccordion
	},
    data() {
        return {};
    },
    methods: {},
};
</script>
  
<style lang="scss" scoped>
</style>
<template>
	<div v-if="content && content?.length > 0" class="image-accordion">
		<AppLink v-for="item in content" :key="item?.fields.title" :to="generateDeepLink(item?.fields?.url)" class="box a1"
			v-bind:style="{ backgroundImage: 'url(' + item?.fields.imageUrl + ')' }"
			v-bind:class="[active === 1 ? 'box--clicked' : '']">
			<div class="mask"></div>
			<div class="image_1">
				<div class="headline">
					<h2 class="mb-1 text-uppercase">{{ item?.fields.title }}</h2>
					<img class="plus" src="@/assets/icon-general-plus.svg" />
				</div>
			</div>
		</AppLink>
	</div>
</template>

<script>
import { generateDeepLink } from "../../helpers/cms.helper";
import AppLink from "@/components/Common/AppLink.vue"

export default {
	name: "ImageAccordion",
	props: ["content"],
	components: {
		AppLink
	},
	data() {
		return {
			active: ""
		};
	},
	methods: {
		toggleClick(itemNum) {
			this.active = itemNum === this.active ? "" : itemNum;
		},
		generateDeepLink
	},
};
</script>

<style lang="scss" scoped>
.image-accordion {
	margin: auto;
}

.mask {
	position: relative;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.45));
	top: 0;
	height: 250px;

	@include media(">lg") {
		height: 700px;
	}

	transition: cubic-bezier(0.6, 0, 0.1, 1) 950ms;
}

.expanded-content {
	background-color: white;
	margin-left: 100px;
	display: none;
	height: 250px;

	@include media(">lg") {
		height: 700px;
	}
}

.image-accordion {
	max-width: 1240px;
	background: rgba(0, 0, 0, 0.1);
	height: 250px;

	@include media(">lg") {
		height: 700px;
	}

	margin: 20px auto;
	display: flex;
	flex-direction: row;
	cursor: pointer;
	margin-bottom: 100px;
}

.box {
	position: relative;
	height: 100%;
	flex: 1;
	background-position: center;
	background-size: cover;
	transition: cubic-bezier(0.6, 0, 0.1, 1) 950ms;

	&--clicked {
		flex: 3;
	}
}

.a1 {
	background-image: url("/images/fashion_1.jpg");
	color: #fff;
}

.a2 {
	background-image: url("/images/case_studies_accordion.jpg");
	color: #fff;
}

.a3 {
	background-image: url("/images/client_area_accordion.jpg");
}

.a4 {
	background-image: url("/images/contact_accordion.jpg");
}

@media screen and (max-width: 800px) {
	.image-accordion {
		flex-direction: column;
		height: 955px;
		box-shadow: none;
	}

	.box {
		box-shadow: none;
	}
}

.headline {
	margin-left: 15px !important;

	@include media(">=lg") {
		margin: 30px;
	}

	h2 {
		color: white;
		font-weight: 300;
		font-size: 30px;
		position: absolute;
		bottom: 30px;
		transition: 0.7s;
		width: 20px;

		@include media("<lg") {
			font-size: 20px;
		}
	}

	.plus {
		display: none;
		position: absolute;
		bottom: 30px;
	}
}

.box:hover {
	.headline {
		h2 {
			bottom: 68px;
			transition: cubic-bezier(0.6, 0, 0.1, 1) 950ms;
		}
	}

	.plus {
		display: block !important;
		transition: cubic-bezier(0.6, 0, 0.1, 1) 950ms;
	}

	.mask {
		display: none !important;
		transition: cubic-bezier(0.6, 0, 0.1, 1) 950ms;
	}
}

.box--clicked {
	.headline {
		display: none;
	}

	.expanded-content {
		display: block;
	}
}</style>

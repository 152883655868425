<template>
	<div v-if="data" class="mb-5">
		<masonry ref="grid" :gutter="30" :cols="{ default: 3, 800: 1, 400: 1 }">
			<app-link v-for="item in filteredData" :key="item?.fields?.title" :to="getLink(item)">
				<b-card no-body>
					<b-card-body>
						<img class="w-100 images" :src="item.fields.imageUrl" />
						<h1>{{ item.fields.title }}</h1>
					</b-card-body>
				</b-card>
			</app-link>
		</masonry>
	</div>
</template>

<script>
import { generateDeepLink } from "@/helpers/cms.helper"
import AppLink from './AppLink'
import slugify from "slugify";

export default {
	name: "ImageGrid",
	props: ["data"],
	components: { AppLink },
	data() {
		return {};
	},
	methods: {
		getLink: (item) => {
			if (item.fields.Urls && item.fields.Urls.indexOf("matterport") > 0) return `/virtual-tour/${slugify(item.fields.title, {lower: true})}`
			return item.fields.Urls ?? generateDeepLink(item.fields.slug);
		}
	},
	computed: {
		filteredData: function() {
			if(this.data?.gridBlocks) {
				return this.data?.gridBlocks?.filter(x => !x.fields.hideInGrid)	
			} else {
				return this.data?.gridBlock
			}
			
		}
	}
};
</script>

<style lang="scss" scoped>
.card {
	border: none;
	margin-top: 30px;

	p {
		margin-top: 18px;
		height: 70px;

		@include media(">lg") {
			height: 72px;
		}
	}
}

h1 {
	font-family: "Miller Banner";
	font-weight: 300;
	font-style: italic;
	font-size: 26px;
	margin-top: 22px;
	color: #212121;
}

@include media("<sm") {
	.vue-grid-item {
		height: fit-content !important;
		transform: none !important;
		position: relative !important;
		width: 100% !important;
	}

	.vue-grid-layout {
		height: 100% !important;
	}
}

.images {
	height: 240px;
	width: 360px;
	object-fit: cover;
}</style>

<template>
	<div class="container-fluid p-0">
		<GradientMaskedHero title="Contact Us"
							longTitle="Contact Us"
							imageFileName="beauty-shot.jpg" />
		<div class="content p-5">
			<div class="spotlight">
				<div class="row">
					<div>
						<div class="h">
							<h class="heading p-4">Thank You For Contacting Us</h>
						</div>
							<p class="p-4">
								Your message has been successfully sent. <br /><br />
								Thank you for your interest in Harrods Partnerships. <br/>
								One of our representatives will be reviewing your request and will send you a response as soon as possible.
							</p>
					</div>
				</div>
			</div>
			<div class="button col-12 col-md-4">
				<b-button class="btn-light" @click="submit">Send Another Message</b-button>
			</div>
		</div>
	</div>
</template>
<script>
	import GradientMaskedHero from "@/components/Common/GradientMaskedHero.vue";

	export default {
		name: "contactConfirmation",
		components: {
			GradientMaskedHero
		},
		data() {
			return {
			};
		},
		async created() { },
		mounted() { },
		updated() { },
		computed: {},
		watch: {},
		methods: {
			submit: function () {
				this.$v.$touch();
				this.$router.push("/contact");
			}
			},
		validations: {},
	};
</script>
<style>
.custom-control-label {
    padding-left: 7px;
    font-size: 14px;
}
.custom-control-label::before, .custom-control-label::after {
    width: 15px;
    height: 15px;
    padding-right: 10px;
}
</style>
<style lang="scss" scoped>
select {
  height: 45px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 25px;
  font-size: 16px;
}

.blurb {
  margin-top: 15px;

  @include media(">lg") {
    margin-top: 30px;
  }
}
	@include media(">md") {
		.fixed-width-lg {
			max-width: 50%;
		}

		.heading {
			font-family: $primary-text-font;
			font-weight: 300;
			font-size: 30px;
			line-height: 40px;
		}

		.content {
			margin-left: 300px;
		}
	}

	@include media("<md") {
		.vue-grid-item {
			height: fit-content !important;
			transform: none !important;
			position: relative !important;
			width: 100% !important;
		}

		.vue-grid-layout {
			height: 100% !important;
		}

		.h, p {
			text-align: center;
		}

		.heading {
			font-family: "Miller Banner";
			font-weight: 300;
			font-size: 30px;
			line-height: 40px;
		}

	}

	.h {
	margin-bottom: 20px;
	}

	.button {
		margin-top: 100px;
		padding: 0px;
		margin-bottom: 100px;
		@include media(">=md") {
			min-width: 178px;
	}
	}

</style>

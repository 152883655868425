<template>
  <div class="hero-banner">
    <div class="hero-banner-bg">
        <picture>
            <!--<source src="/images/partnerships_header.jpg"
            media="(max-width: 575px)" />-->
            <!--:srcset="`/images/hero/m/${imageFileName}`"-->
            <!--<source media="(min-width: 576px) and (max-width: 767px)" />-->
            <!--:srcset="`/images/hero/t/${imageFileName}`"-->
            <!--<source :srcset="`/images/hero/sd/${imageFileName}`"
            media="(min-width: 768px) and (max-width: 991px)" />
    <source :srcset="`/images/hero/d/${imageFileName}`"
            media="(min-width: 992px)" />-->
            <img :src="image"
                 alt=""
                 class="background-img"
                 role="presentation"
                 loading="lazy" />
            <!--:src="`/images/hero/d/${imageFileName}`"-->
            <!--srcset=""-->
        </picture>
      <div class="hero-banner-gradient"></div>
      <h1 class="d-md-none title" v-bind:class="{ 'title__subtitle-enabled': subtitle && subtitle }" v-html="title"></h1>
      <h1 class="d-none title d-md-block title" v-bind:class="{ 'title__subtitle-enabled': subtitle }" v-html="longTitle || title" style="white-space: pre;"></h1>
      <h2 class="subtitle">{{subtitle}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "GradientMaskedHero",
  props: {
    title: String,
    longTitle: String,
    subtitle: String,
    image: String,
  },
};
</script>

<style lang="scss" scoped>
.background-img {
  object-fit: cover;
  object-position: top center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero-banner {
  height: 300px;
  position: relative;
  margin-bottom: 40px;

  @include media(">=md") {
    height: 445px;
  }

  @include media(">=lg") {
    height: 280px;
  }

  @include media(">=xl") {
    height: 480px;
  }

  .subtitle
  {
    position: absolute;
    bottom: 20px;
    margin-left: 30px;
    color: $harrods-white;
    font-family: "Miller Banner";
    font-weight: 300;
    font-size: 18px;

    @include media("<=sm") {
      margin-left: 40px; 
    }

    @include media(">=md") {
      margin-left: 80px;
      font-size: 22px;
      bottom: 50px;
    }

    @include media(">=xl") {
      margin-left: 180px;
      font-size: 24px;
      bottom: 80px;
    }
  }

  .title {
    position: absolute;
    bottom: 10px;
    color: $harrods-white;
    font-size: 40px;
    text-transform: uppercase;
    text-align: left;
    margin-left: 30px;
    width: 315px;
    font-weight: 300;

    &__subtitle-enabled {
      bottom: 64px;
    }


  @include media("<=sm") {
    margin-left: 40px;
  }

    @include media(">=md") {
      width: 640px;
      margin-left: 80px;
      bottom: 50px;
      font-size: 48px;

      &__subtitle-enabled {
        bottom: 98px;
      }
    }

    @include media(">=xl") {
      width: 815px;
      margin-left: 180px;
      bottom: 50px;
      font-size: 60px;

      &__subtitle-enabled {
        bottom: 132px;
      }
    }
  }
}
.hero-banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

  .hero-banner-gradient {
    object-fit: contain;
    height: 300px;
    width: 100%;
    opacity: 0.4;
    position: absolute;
    top: 0;
    background-image: linear-gradient( to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.12) 12%, rgba(0, 0, 0, 0.23) 23%, rgba(0, 0, 0, 0.33) 33%, rgba(0, 0, 0, 0.41) 41%, rgba(0, 0, 0, 0.49) 49%, rgba(0, 0, 0, 0.56) 56%, rgba(0, 0, 0, 0.63) 63%, rgba(0, 0, 0, 0.69) 69%, rgba(0, 0, 0, 0.74) 74%, rgba(0, 0, 0, 0.8) 80%, rgba(0, 0, 0, 0.85) 85%, rgba(0, 0, 0, 0.9) 90%, rgba(0, 0, 0, 0.95) 95%, #000 );

    @include media(">=md") {
      height: 445px;
    }

    @include media(">=lg") {
      height: 280px;
    }

    @include media(">=xl") {
      height: 480px;
    }
  }
</style>

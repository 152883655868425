<template>
    <b-alert show variant="success" class="mb-4">
        <div class="row">
            <div class="col-auto text-center">
                {{ msg }}
            </div>
        </div>
    </b-alert>
</template>

<script>
export default {
  name: 'Success',
  props: {
    msg: String
  }
}
</script>

<style lang="scss" scoped>

.alert-success {
    color: $success-green;
    font-size: 16px;
    background-color: $success-light-green;
    border: solid 1px $success-green;
    border-radius: 0px;
}

</style>

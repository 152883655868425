<template>
  <div class="mega-menu">
    <div v-if="selectedMenuItem">
      <a @click="selectedMenuItem = null">
        <div class="container-fluid title-container justify-content-start p-0">
          <img class="title-chevron align-self-center" src="@/assets/Header/icon-general-chevron-right-gold.svg" />
          <h1 class="mb-0 sub-menu-title">{{ selectedMenuItem.fields.title }}</h1>
        </div>
      </a>
      <div class="mobile-menu-parent-div bg-white">
        <div v-for="menuItem in selectedMenuItem.fields.navigation2Links" :key="menuItem.fields.slug"
          class="mobile-menu-child-div mr-auto w-100 d-flex">
          <AppLink v-bind:to="{
            path: generateDeepLink(menuItem.fields.slug),
          }" class="mobile-menu-item mr-auto w-100">{{ menuItem.fields.title }}
            <img class="chevron" src="@/assets/Header/icon-general-chevron-right-gold.svg" />
          </AppLink>
        </div>
      </div>
    </div>
    <div v-if="!selectedMenuItem && menuItems">
      <div class="mobile-menu-parent-div bg-white">
        <div v-for="menuItem in menuItems" :key="menuItem.fields.slug" class="mobile-menu-child-div w-100 d-flex"
          @click="handleClick(menuItem)">
          <span v-if="menuItem.fields.navigation2Links && menuItem.fields.navigation2Links.length > 0" class="mobile-menu-item mr-auto w-100">
            {{ menuItem.fields.title }}
            <img class="chevron" src="@/assets/Header/icon-general-chevron-right-gold.svg" />
          </span>
          <a v-else-if="menuItem.fields.slug.startsWith('http')" :href="menuItem.fields.slug" class="mobile-menu-item mr-auto w-100">{{
            menuItem.fields.title }}
            <img class="chevron" src="@/assets/Header/icon-general-chevron-right-gold.svg" />
          </a>
          <AppLink v-else :key="$router.fullPath" v-bind:to="{
            path: generateDeepLink(menuItem.fields.slug),
          }" class="mobile-menu-item mr-auto w-100">
            {{ menuItem.fields.title }}
            <img class="chevron" src="@/assets/Header/icon-general-chevron-right-gold.svg" />
          </AppLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateDeepLink } from '../../helpers/cms.helper';
import AppLink from '@/components/Common/AppLink.vue'

export default {
  name: "MegaMenuMobile",
  props: ["menuItems"],
  components: { AppLink },
  mounted() {
  },
  data() {
    return {
      selectedMenuItem: null,
    };
  },
  computed: {},
  methods: {
    handleClick(menuItem) {
      if (menuItem.fields.navigation2Links && menuItem.fields.navigation2Links.length > 0) {
        this.selectedMenuItem = menuItem;
      }
    },
    generateDeepLink
  }
};
</script>

<style lang="scss" scoped>
.chevron {
  width: 20px;
  height: 20px;
  float: right;
}

.department-menu {
  margin-top: 21px;

  .title {
    width: 160px;
  }
}

.title-container {
  background-color: #f5f5f5;
  height: 72px;

  @include media(">md") {
    margin-top: 21px;
  }
}

.title-chevron {
  transform: rotate(-180deg);
  margin-left: 30px;
}

h1 {
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  margin-left: 20px;
}

.title {
  margin: 10px 0 8px 20px;
  font-family: "Miller Banner";
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
  line-height: 1.33;
  color: #424242;
  text-align: left;
}

.sub-menu-title {
  font-family: "Founders Grotesk";
  font-size: 15px;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}
</style>


<template>
  <div class="iframe-container">
    <iframe :src='this.iframeUrl' frameborder='0' allowfullscreen scrolling="no"
      allow='xr-spatial-tracking'></iframe>
  </div>
</template>
<script>
import { getContentForPage } from "@/api/contentful.api"
var slugify = require('slugify')


export default {
  name: "ThreeSixtyTour",
  components: {
  },
  props: ["identifier"],
  data() {
    return {
      content: [],
      iframeUrl: ""
    };
  },
  async created() {
		try {
			this.$global.isLoading = true;
			let response = await getContentForPage("partnershipHomepage", "virtual-tours");
			this.content = response;
		} catch (error) {
			console.error(error);
		}
		finally {
			this.$global.isLoading = false;
		}
    this.iframeUrl = this.virtualTour[0]?.fields?.Urls;
	},
  mounted() { },
  updated() { },
  computed: {
    virtualTour: function () {
      let content= this.content;
      var filteredContent = content.filter(item => item.name === "pictureGridWithHideFunction");
      let gridBlocks = filteredContent.flatMap(item => 
        item?.data?.gridBlocks.filter(
          (x) => slugify(x.fields.title, { lower: true }) === this.identifier.toLowerCase()
        )
      );
      return gridBlocks;
    },
  },
  watch: {},
  methods: {
  },
};
</script>
<style scoped>
iframe {
  display: block;
  /* iframes are inline by default */
  background: #000;
  border: none;
  /* Reset default border */
  height: 100vh;
  /* Viewport-relative units */
  width: 100vw;
}

.iframe-container {
  overflow-x: hidden;
}
</style>

<template>
  <div class="row mb-5 no-gutters flex-column-reverse flex-lg-row"
    v-bind:class="[data.alignment === 'Image on Right' ? '' : 'flex-lg-row-reverse']">
    <div class="col-12 col-lg-6">
      <div class="pt-5 m-lg-5 p-lg-5">
        <h1 class="mb-5">{{ data.title }}</h1>
        <div class="mb-5" v-html="documentToHtmlString(data.body)"></div>
      </div>
    </div>
    <div class="col-12 col-lg-6 pt-5 pt-lg-0 mt-3 mt-lg-0">
      <img :src="data.imageUrl" />
    </div>
  </div>
</template>
  
<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
  name: "TwoColumn",
  props: ["data"],
  data() {
    return {};
  },
  methods: { documentToHtmlString },
};
</script>
  
<style lang="scss" scoped>
h1 {
  font-family: "Miller Banner";
  font-weight: 300;
  font-style: italic;
  font-size: 26px;
  margin-top: 22px;
  color: #212121;

  @include media(">=lg") {
    font-size: 40px;
  }
}

h2 {
  font-family: "Miller Banner";
  font-weight: 300;
  font-style: italic;
  font-size: 26px;
  margin-top: 22px;
  color: #212121;

  @include media(">=lg") {
    font-size: 30px;
  }
}

@include media("<sm") {
  .vue-grid-item {
    height: fit-content !important;
    transform: none !important;
    position: relative !important;
    width: 100% !important;
  }

  .vue-grid-layout {
    height: 100% !important;
  }
}

.images {
  height: 240px;
  width: 360px;
  object-fit: cover;
}
</style>
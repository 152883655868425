<template>
    <p class="blurb pb-5">
        {{ this.data?.subtitle?.content[0]?.content[0]?.value }}
    </p>
</template>
  
<script>
export default {
    name: "Subtitle",
    props: ["data"],
    data() {
        return {};
    },
    methods: {},
};
</script>
  
<style lang="scss" scoped>
.blurb {
	font-size: 18px;
	margin-top: 50px;
	margin-left: 30px;
	margin-right: 30px;
	text-align: center;
	line-height: 1.56;
	font-family: "MillerBanner-Light";

	@include media(">=lg") {
		margin-left: 100px;
		margin-right: 100px;
		font-size: 18px;
	}

	@include media(">=xl") {
		margin-left: 300px;
		margin-right: 300px;
		font-size: 22px;
	}
}

</style>
  
<template>
    <div class="showcase-bg">
        <div class="showcase">
            <div class="container-fluid p-0 fixed-width-lg">
                <h1 class="text-center mb-5" style="color: white; font-size: 30px">
                    {{ this.data.title }}
                </h1>
                <flip-countdown ref="countdown" :deadline="deadlineLocal"
                    @timeElapsed="timeElapsedHandler"></flip-countdown>
                <div class="video-container" :class="!isLive && !timeElapsed ? 'novideo' : ''">
                    <iframe v-if="isLive || timeElapsed" :src="`${videoUrl}`" title="Harrods Showcase" frameborder="0"
                        modestbranding
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                    <img v-else class="w-100" src="@/assets/placeholder-img.png" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FlipCountdown from "@/components/FlipCountdown.vue";
import moment from "moment";

export default {
    name: "Showcase",
    props: ["data"],
    components: {
        FlipCountdown
    },
    data() {
        return {
            deadlineDate: null,
            deadlineLocal: '',
            videoUrl: null,
            timeElapsed: false
        };
    },
    async created() {
        this.deadlineDate = moment(this.data["go_live_time"]).utc(true);
        let startTimeEpoch = this.deadlineDate.valueOf();
        let d = new Date(0);
        d.setUTCMilliseconds(startTimeEpoch);

        let month = d.getMonth() + 1;
        let day = d.getDate();

        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;

        this.deadlineLocal =
            d.getFullYear() + "-" + month + "-" + day + " " + d.getHours() + ":00:00";

        this.videoUrl = this.data.videoUrl;
    },
    methods: {
        timeElapsedHandler: function () {
            this.timeElapsed = true;
        },
    },
    computed: {
        isLive() {
            return this.deadlineDate?.isBefore(moment.utc());
        }
    }
};
</script>

<style>
.custom-control-label {
  padding-left: 7px;
  font-size: 14px;
}

.custom-control-label::before,
.custom-control-label::after {
  width: 15px;
  height: 15px;
  padding-right: 10px;
}
</style>
<style lang="scss" scoped>
.video-container {
  border: 2px solid #ab8e66;
}

.password {
  max-width: 80%;
}

@include media(">lg") {
  .password {
    max-width: 35%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

h1 {
  color: white;
}

.showcase-bg {
  background-image: url("/events-bg.png");
  background-repeat: repeat;
  background-size: cover;
  margin-top: 121px;
}

select {
  height: 45px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 25px;
  font-size: 16px;
}

.blurb {
  margin-top: 15px;

  @include media(">lg") {
    margin-top: 30px;
  }
}

@include media(">lg") {
  .fixed-width-lg {
    max-width: 90%;
  }
}

.showcase {
  padding-top: 50px;
  padding-bottom: 80px;
  margin-left: 25px;
  margin-right: 25px;

  @include media(">lg") {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@include media("<lg") {
  .video-container {
    width: 100%;
    height: 400px;
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}

@include media(">lg") {
  .video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
  }
}

.card {
  border: none;
  margin-top: 30px;

  p {
    margin-top: 18px;
    height: 70px;

    @include media(">lg") {
      height: 72px;
    }
  }
}

h1 {
  font-family: "Miller Banner";
  font-weight: 300;
  font-style: italic;
  font-size: 26px;
  margin-top: 22px;
  color: #212121;
}

@include media("<sm") {
  .vue-grid-item {
    height: fit-content !important;
    transform: none !important;
    position: relative !important;
    width: 100% !important;
  }

  .vue-grid-layout {
    height: 100% !important;
  }
}

.novideo {
  padding-bottom: 0 !important;
  height: unset !important;
}
</style>
import axios from 'axios'

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_CONTENT_API_BASE_URL,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json'
  }
});

httpClient.interceptors.request.use((config) => {
  config.params = {...config.params, environmentCode: process.env.VUE_APP_CONTENT_DEFAULT_ENVIRONMENT}
  return config
})

httpClient.interceptors.response.use(undefined, (err) => {
  const { config, response } = err;
    
  // Only retry if receive Not Found response and it is not the live environment
  if (response.status !== 404 || config.params['environmentCode'] == 'live') {
    return Promise.reject(err);
  }

  config.params['environmentCode'] = 'live';
  const retry = new Promise((resolve) => {
      console.log("retrying the request in live environment", config.url);
      resolve();
  });
  return retry.then(() => axios(config));
});

export default httpClient
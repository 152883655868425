// Import the Contentful library
import { createClient } from 'contentful';

const client = createClient({
    space: '89byz2qptwbm',
    accessToken: 'Cnmtga8XYYERcLE5kVs3QyaFsEyb3R7t2a4MEHEORnY',
    host: 'cdn.eu.contentful.com',
    environment: process.env.VUE_APP_CONTENT_DEFAULT_ENVIRONMENT
})

const getNavigation = async () => {
    try {
        const response = await client.getEntries({
            content_type: 'navigationMenu',
            'fields.name': 'MegaMenu',
            include: 2 // Include links 2 levels deep
        });

        if (response.items.length > 0) {
            // Assuming there is at least one item that matches the ID
            return response.items[0].fields.navigationElements;
        } else {
            console.log('No items found with the specified ID.');
        }
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }

    return [];
}

const getContentForPage = async (contentType, slug, include = 2) => {
    try {
        const response = await client.getEntries({
            content_type: contentType,
            'fields.slug': slug,
            include: include
        });
        if (response.items.length > 0) {
            if (slug === 'showcase') {            
                let items = response.items.map((x) => ({name: x.sys.contentType.sys.id,  data: x.fields}));
                return items;
            } else {
                let pageFormat = response.items[0].fields.FormatofPage;
                let items = response.includes.Entry;
                let orderMap = {};

                for (let i = 0; i < pageFormat.length; i++) {
                orderMap[pageFormat[i].sys.id] = i;
                }

                let flattenedItems = [];

                const flattenItems = (items, orderMap, flattenedItems) => {
                items.forEach(item => {
                    if (orderMap[item.sys.id] !== undefined) {
                    flattenedItems[orderMap[item.sys.id]] = item;
                    }

                    if (item.fields.accordionItems) {
                    item.fields.accordionItems.forEach(nestedItem => {
                        if (orderMap[nestedItem.sys.id] !== undefined) {
                        flattenedItems[orderMap[nestedItem.sys.id]] = nestedItem;
                        }
                    });
                    }
                });

                return flattenedItems.filter(item => item !== undefined);
                };

                let reorderedArray = flattenItems(items, orderMap, flattenedItems);

                let finalArray = reorderedArray.map((x) => ({ name: x.sys.contentType.sys.id, data: x.fields }));
                return finalArray;
            }
        } 
        else {
            console.log('No items found with the specified content type and slug.');
        }
    } catch (error) {
        console.error('Failed to fetch data:', error);
    }

    return [];
};


export {
    getNavigation,
    getContentForPage
}
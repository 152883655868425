<template>
  <div>
    <div class="container-fluid p-0">
			<GradientMaskedHero :title="heroContent?.short_title ?? heroContent?.shord_title" :longTitle="heroContent?.long_title"
				:image="heroContent?.image.imageMd.url" />
    </div>
    <div class="container-fluid p-0 pb-5 mb-5">
      <form ref="form" class="form">
        <div class="row">
          <div class="col-12 col-lg-6">
            <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">First Name</label>
            <input
              class="form-control input-field pl-4"
              type="text"
              name="FirstName"
              id="FirstName"
              v-model.trim.lazy="$v.firstName.$model"
              v-bind:class="{
                'error-box':
                  $v.firstName.$dirty &&
                  (!$v.firstName.required || !$v.firstName.lettersOnly),
              }"
            />
            <div class="error-text" v-if="$v.firstName.$dirty">
              <p v-if="!$v.firstName.required || !$v.firstName.lettersOnly">
                Please enter a valid first name.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Last Name</label>
            <input
              class="form-control input-field pl-4"
              type="text"
              name="LastName"
              id="LastName"
              v-model.trim.lazy="$v.lastName.$model"
              v-bind:class="{
                'error-box':
                  $v.lastName.$dirty &&
                  (!$v.lastName.required || !$v.lastName.lettersOnly),
              }"
            />
            <div class="error-text" v-if="$v.lastName.$dirty">
              <p v-if="!$v.lastName.required || !$v.lastName.lettersOnly">
                Please enter a valid last name.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Email</label>
            <input
              class="form-control input-field pl-4"
              type="email"
              name="email"
              id="email"
              v-on:change="handleInputOnChange"
              v-model.trim.lazy="$v.email.$model"
              v-bind:class="{
                'error-box':
                  $v.email.$dirty && (!$v.email.email || !$v.email.required),
              }"
            />
            <div class="error-text" v-if="$v.email.$dirty">
              <p v-if="!$v.email.email || !$v.email.required">
                Please enter a valid email address.
              </p>
            </div>
          </div>

          <div class="col-12 col-lg-6">
            <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Company name</label>
            <input type="hidden" name="accName" value="harrodsmedia" />
            <input type="hidden" name="listName" value="2020 E-Marketing" />
            <input type="hidden" name="successUrl" value="NO-REDIRECT" />
            <input type="hidden" name="errorUrl" value="" />
            <input
              class="form-control input-field pl-4"
              type="text"
              name="Company"
              id="Company"
              v-model.trim.lazy="$v.companyName.$model"
              v-bind:class="{
                'error-box': $v.companyName.$dirty && !$v.companyName.required,
              }"
            />
            <div class="error-text" v-if="$v.companyName.$dirty">
              <p v-if="!$v.companyName.required">
                Please enter a company name.
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6">
            <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Job title</label>
            <input
              class="form-control input-field pl-4"
              type="text"
              name="job-title"
              id="job-title"
              v-model.trim.lazy="$v.jobTitle.$model"
              v-bind:class="{
                'error-box': $v.jobTitle.$dirty && !$v.jobTitle.required,
              }"
            />
            <div class="error-text" v-if="$v.jobTitle.$dirty">
              <p v-if="!$v.jobTitle.required">Please enter a job title.</p>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3"
              >Phone number (optional)</label
            >
            <input
              class="form-control input-field pl-4"
              type="text"
              name="PhoneNumber"
              id="PhoneNumber"
              @keypress="isNumber($event)"
              v-model.trim.lazy="$v.phoneNumber.$model"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label class="label pt-3 pt-lg-0 mt-3 mt-lg-3">Your Message</label>
            <textarea
              v-model.trim.lazy="$v.message.$model"
              v-bind:class="{
                'error-box': $v.message.$dirty && !$v.message.required,
              }"
              class="message form-control input-field pl-4"
            />
            <div class="error-text" v-if="$v.message.$dirty">
              <p v-if="!$v.message.required">Please enter a message.</p>
            </div>
          </div>
        </div>

        <div class="pl-4">
          <b-form-checkbox
            name="event_confirm_policy"
            id="event_confirm_policy"
            value="event_confirm_policy"
            class="pt-3"
            v-model="checked_pol"
          >
            I have read and accepted the
            <a
              target="_new"
              href="https://www.harrods.com/en-gb/legal/security-and-privacy"
              >Terms and Conditions and Privacy Policy</a
            >
          </b-form-checkbox>
          <b-form-checkbox
            name="event_confirm_marketing"
            id="event_confirm_marketing"
            value="event_confirm_marketing"
            class="pt-3"
            v-model="checked_marketing"
          >
            I accept receiving marketing information from Harrods Partnerships
            (optional)
          </b-form-checkbox>
        </div>

        <div class="m-2">
          <Success v-if="sending" msg="Sending..." />
          <Success v-if="sent" msg="Message sent" />
          <Error v-if="errored" msg="An error occured." />
        </div>

        <div class="col-12 col-lg-5">
          <b-button
            variant="primary mt-5"
            @click="submit"
            :disabled="!isComplete"
            >Send Message</b-button
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import Success from "@/components/Alerts/Success.vue";
import Error from "@/components/Alerts/Error.vue";
import { submitQuestion } from "@/api/questions.api";
import GradientMaskedHero from "@/components/Common/GradientMaskedHero.vue";
import { getContent } from "@/api/content.api"

const lettersOnly = function (value) {
  return /^[-'a-zA-Z�-�'\s]*$/.test(value);
};

export default {
  name: "Contact",
  components: {
    Success,
    Error,
    GradientMaskedHero,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
      jobTitle: "",
      phoneNumber: "",
      sending: false,
      sent: false,
      errored: false,
      checked_pol: false,
      checked_marketing: false,
      message: "",
      content: [],
    };
  },
  async created() {
		try {
			this.$global.isLoading = true;
			let response = await getContent("harrods-partnerships", "contact");
			this.content = response.data;
		} catch (error) {
			console.error(error);
		}
		finally {
			this.$global.isLoading = false;
		}
	},
  mounted() {},
  updated() {},
  computed: {
    isComplete() {
      return (
        this.companyName &&
        this.firstName &&
        this.lastName &&
        this.email &&
        this.jobTitle &&
        this.message &&
        this.checked_pol
      );
    },
    heroContent() {
			return this.content["hp.Hero"];
		}
  },
  watch: {},
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleInputOnChange() {
      this.email = this.email.toLowerCase();
    },
    submit: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        submitQuestion({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          companyName: this.companyName,
          jobTitle: this.jobTitle,
          phoneNumber: this.phoneNumber,
          consentedPolicy: this.checked_pol,
          consentedMarketing: this.checked_marketing,
          message: this.message,
        }).then(() => {
          this.sent = true;
          this.sending = false;
          this.errored = false;
          this.$router.push("/contact-confirmation");
        });
      }
    },
  },
  validations: {
    companyName: {
      required,
    },
    firstName: {
      required,
      lettersOnly,
    },
    lastName: {
      required,
      lettersOnly,
    },
    email: {
      required,
      email,
    },
    jobTitle: {
      required,
    },
    phoneNumber: {},
    message: {
      required,
    },
    checked_pol: {
      required,
    },
  },
};
</script>
<style>
.custom-control-label {
  padding-left: 7px;
  font-size: 14px;
}
.custom-control-label::before,
.custom-control-label::after {
  width: 15px;
  height: 15px;
  padding-right: 10px;
}
</style>
<style lang="scss" scoped>
select {
  height: 45px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 25px;
  font-size: 16px;
}

.blurb {
  margin-top: 15px;

  @include media(">lg") {
    margin-top: 30px;
  }
}
@include media(">lg") {
  .fixed-width-lg {
    max-width: 50%;
  }
}
.home {
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 25px;
  @include media(">lg") {
    margin-top: 50px;
    margin-left: 200px;
    margin-right: 200px;
    margin-bottom: 200px;
  }
}
.card {
  border: none;
  margin-top: 30px;

  p {
    margin-top: 18px;
    height: 70px;
    @include media(">lg") {
      height: 72px;
    }
  }
}
h1 {
  font-family: "Miller Banner";
  font-weight: 300;
  font-style: italic;
  font-size: 26px;
  margin-top: 22px;
  color: #212121;
}
@include media("<sm") {
  .vue-grid-item {
    height: fit-content !important;
    transform: none !important;
    position: relative !important;
    width: 100% !important;
  }

  .vue-grid-layout {
    height: 100% !important;
  }
}
.message {
  height: 79px;
}

.row {
  margin: 0px;
}

.form {
  @include media(">=xl") {
    margin: 0 300px;
  }

  @include media("<xl") {
    margin: 0 200px;
  }

  @include media("<lg") {
    margin: 0 100px;
  }

  @include media("<md") {
    margin: 0 50px 115px 30px;
  }
}
</style>

<template>
	<div class="home">
		<div class="container-fluid p-0">
			<GradientMaskedHero :title="documentToHtmlString(heroContent?.short_title)" :longTitle="documentToHtmlString(heroContent?.long_title)"
				:image="heroContent?.imageUrl" />
			<div class="content">
				<component v-for="(comp, index) in pageComponents" :key="index" :is="comp.type" v-bind="{ data: comp.data }">
				</component>
			</div>
			<div>
				<page-full-width-banner />
			</div>
		</div>
	</div>
</template>

<script>
import GradientMaskedHero from "@/components/Common/GradientMaskedHero.vue";
import PageFullWidthBanner from "@/components/Common/PageFullWidthBanner.vue";
import { getContentForPage } from "@/api/contentful.api"
import { getComponents } from "@/helpers/cms.helper"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';


export default {
	name: "Home",
	components: {
		GradientMaskedHero,
		PageFullWidthBanner
	},
	data() {
		return {
			content: [],
			pageComponents: []
		}
	},
	async created() {
		try {
			this.$global.isLoading = true;
			let response = await getContentForPage("partnershipHomepage", "/");
			this.content = response;
			this.pageComponents = getComponents(this.content);
		} catch (error) {
			console.error(error);
		}
		finally {
			this.$global.isLoading = false;
		}
	},
	mounted() { },
	updated() { },
	methods: { documentToHtmlString },
	computed: {
		videoElement() {
			return this.$refs.video;
		},
		heroContent() {
			return this.content?.find(x => x.name === 'partnershipsTitle')?.data;
		}
	},
	watch: {}
}
</script>
<style lang="scss" scoped>
.home {
	@include media(">lg") {
		margin-top: 50px;
	}
}

.card {
	border: none;
	margin-top: 30px;

	p {
		margin-top: 18px;
		height: 70px;

		@include media(">lg") {
			height: 72px;
		}
	}
}

h1 {
	font-family: "Miller Banner";
	font-weight: 300;
	font-style: italic;
	font-size: 26px;
	margin-top: 22px;
	color: #212121;
}

@include media("<sm") {
	.vue-grid-item {
		height: fit-content !important;
		transform: none !important;
		position: relative !important;
		width: 100% !important;
	}

	.vue-grid-layout {
		height: 100% !important;
	}
}
</style>

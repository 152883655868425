import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import VueMasonry from 'vue-masonry-css';
import Vuelidate from 'vuelidate'
import VueLazyLoadVideo from 'vue-lazyload-video'
import VueGtm from '@gtm-support/vue2-gtm';
// CMS Modules
import Spotlight from './components/Common/Spotlight.vue';
import Subtitle from './components/Common/Subtitle.vue';
import LatestTrends from './components/Common/LatestTrends.vue';
import ImageGrid from './components/Common/ImageGrid.vue';
import YouMayAlsoLike from './components/Common/YouMayAlsoLike.vue'
import TwoColumn from './components/Common/TwoColumn.vue'
import Showcase from './components/Common/Showcase.vue'

Vue.use(VueLazyLoadVideo)

Vue.use(VueGtm, {
  id: 'GTM-M98RBNS', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: window.webpackHotUpdate, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

Vue.use(VueMasonry);
Vue.use(Vuelidate);

Vue.component("Spotlight", Spotlight);
Vue.component("Subtitle", Subtitle);
Vue.component("LatestTrends", LatestTrends);
Vue.component("ImageGrid", ImageGrid);
Vue.component("YouMayAlsoLike", YouMayAlsoLike);
Vue.component("TwoColumn", TwoColumn);
Vue.component("Showcase", Showcase);

Vue.prototype.$global = Vue.observable({
  isLoading: false 
});

Vue.config.productionTip = false

Vue.filter('toCurrency', function (value, numOfDecimals = 0) {
  if (typeof value !== 'number') {
    value = parseFloat(value);
  }
  var formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: numOfDecimals
  });
  return formatter.format(value);
});

Vue.filter('truncate', function (str, num) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...'
});


Vue.filter('highlight', function (word, query) {
    var check = new RegExp(query, "ig");
    return word.toString().replace(check, function (matchedText) {
        return ('<strong style="font-weight:500;">' + matchedText + '</strong>');
    });
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
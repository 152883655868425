<template>
  <div class="home">
    <div class="container-fluid p-0">
      <div class="content">
        <component v-for="(comp, index) in pageComponents" :key="index" :is="comp.type" v-bind="{ data: comp.data }">
				</component>
      </div>
    </div>
  </div>
</template>

<script>
import { getContentForPage } from "@/api/contentful.api"
import { getComponents } from "@/helpers/cms.helper"

export default {
  name: "ShowcasePage",
  data() {
    return {
			content: [],
			pageComponents: []
		}
  },
  async created() {
		try {
			this.$global.isLoading = true;
			let response = await getContentForPage("hp.showcase", "showcase");
			this.content = response;
			this.pageComponents = getComponents(this.content);
		} catch (error) {
			console.error(error);
		}
		finally {
			this.$global.isLoading = false;
		}
	},
  updated() { },
  watch: {},
  computed: {
  },
  methods: {
    timeElapsedHandler: function () {
      this.timeElapsed = true;
    }
  }
};
</script>

<template>
  <div class="full-width-banner">
    <div class="banner-bg mt-5">
      <div class="gradient-bg"></div>
    </div>
    <div class="banner-container">
      <div class="container-fluid box-container m-auto">
        <div class="row mx-md-5">
          <div class="col">
            <h1 class="heading">Subscribe to <br /> Newsletter</h1>
          </div>
        </div>
        <div class="row mx-md-5">
          <div class="col">
            <p>
              To keep up to date with our latest campaign news and brand-marketing opportunities, please subscribe to the Harrods Partnerships newsletter.
            </p>
          </div>
        </div>
        <div class="row pt-5 mx-md-5">
          <div class="col">
            <router-link
              to="/mailing-list"
              target="_blank"
              class="float-left"
              >Subscribe</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageFullWidthBanner",
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.full-width-banner {
  position: relative;
}
	.banner-bg {
		background: url("/images/harrods texture background.jpg");
		background-size: cover;
		background-repeat: no-repeat;
		height: 385px;

		@include media(">=md") {
			height: 360px;
			background: url("/images/harrods texture background.jpg");
			background-size: cover;
			background-repeat: no-repeat;
		}

		@include media(">=lg") {
			height: 394px;
			background: url("/images/harrods texture background.jpg");
			background-size: cover;
			background-repeat: no-repeat;
		}

		@include media(">=xl") {
			height: 505px;
			background: url("/images/harrods texture background.jpg");
			background-size: cover;
			background-repeat: no-repeat;
		}
	}

.box-container {
  padding-top: 20px;
}
@include media(">=md") {
  .box-container {
    border: solid 2px #fff;
    position: relative;
    margin: auto;
    height: 322px;
    padding-top: 40px;

    @include media(">=md") {
      height: 300px;

      h1 {
        width: 488px;
      }
    }

    @include media(">=lg") {
      height: 314px;
    }

    @include media(">=xl") {
      height: 385px;
    }
  }
}
.banner-container {
  color: #fff;
  position: absolute;
  top: 0;
  padding: 25px;
  width: 100%;
  height: 100%;

  @include media(">=md") {
    padding: 30px;
  }

  @include media(">=lg") {
    padding: 40px;
  }

  @include media(">=xl") {
    padding-left: 220px;
    padding-right: 220px;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h1 {
    font-size: 30px;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    @include media(">=xl") {
      font-size: 40px;
      width: 630px;
    }
  }

  p {
    padding-top: 20px;

    @include media(">=xl") {
      width: 630px;
    }

    line-height: 1.5;

    .strong {
      font-weight: normal;
    }
  }

  a {
    color: #fff;
    font-weight: normal;
    text-decoration: underline;
  }
}

.gradient-bg {
  object-fit: contain;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.12) 12%,
    rgba(0, 0, 0, 0.23) 23%,
    rgba(0, 0, 0, 0.33) 33%,
    rgba(0, 0, 0, 0.41) 41%,
    rgba(0, 0, 0, 0.49) 49%,
    rgba(0, 0, 0, 0.56) 56%,
    rgba(0, 0, 0, 0.63) 63%,
    rgba(0, 0, 0, 0.69) 69%,
    rgba(0, 0, 0, 0.74) 74%,
    rgba(0, 0, 0, 0.8) 80%,
    rgba(0, 0, 0, 0.85) 85%,
    rgba(0, 0, 0, 0.9) 90%,
    rgba(0, 0, 0, 0.95) 95%,
    #000
  );

  @include media(">md") {
    background-image: unset;
    opacity: 1;
  }

  @include media(">=xl") {
    opacity: 0.3;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.12) 12%,
      rgba(0, 0, 0, 0.23) 23%,
      rgba(0, 0, 0, 0.33) 33%,
      rgba(0, 0, 0, 0.41) 41%,
      rgba(0, 0, 0, 0.49) 49%,
      rgba(0, 0, 0, 0.56) 56%,
      rgba(0, 0, 0, 0.63) 63%,
      rgba(0, 0, 0, 0.69) 69%,
      rgba(0, 0, 0, 0.74) 74%,
      rgba(0, 0, 0, 0.8) 80%,
      rgba(0, 0, 0, 0.85) 85%,
      rgba(0, 0, 0, 0.9) 90%,
      rgba(0, 0, 0, 0.95) 95%,
      #000
    );
  }
}

.heading {
    @include media("<lg") {
        font-size: 40px !important;
    }

    font-size: 60px !important;
}
</style>

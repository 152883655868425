<template>
    <b-alert show variant="danger" class="mb-5">
        <div class="row">
            <div class="col-auto text-center">
                <span style="white-space: pre-wrap;">{{ msg }}</span>
            </div>
        </div>
    </b-alert>
</template>

<script>
export default {
  name: 'Error',
  props: {
    msg: String
  }
}
</script>

<style lang="scss" scoped>

.alert-danger {
    color: $error-red;
    font-size: 16px;
    background-color: $error-light-red;
    border: solid 1px $error-red;
    border-radius: 0px;
}

</style>

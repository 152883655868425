import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Showcase from "../views/Showcase.vue";
import CaseStudies from "../views/CaseStudies.vue";
import CaseStudiesLinks from "../views/CaseStudiesLinks.vue";
import MailingList from "../views/MailingList.vue";
import RequestMediaPack from "../views/RequestMediaPack.vue";
import Partnerships from "../views/Partnerships.vue";
import PartnershipDepartment from "../views/PartnershipDepartment.vue";
import Contact from "../views/Contact.vue";
import VirtualTours from "../views/VirtualTours.vue";
import Tour from "../views/Tour.vue";
import contactConfirmation from "../views/contactConfirmation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/case-studies",
    name: "CaseStudies",
    component: CaseStudies,
  },
  {
    path: "/showcase",
    name: "Showcase",
    component: Showcase,
  },
  {
    path: "/partnerships",
    name: "Partnerships",
    component: Partnerships,
  },
  {
    path: "/virtual-tours",
    name: "Virtual Tours",
    component: VirtualTours,
  },
  {
    path: "/partnerships/:identifier",
    name: "PartnershipDepartment",
    component: PartnershipDepartment,
    props: true,
  },
  {
    path: "/case-studies/:identifier",
    name: "CaseStudiesLinks",
    component: CaseStudiesLinks,
    props: true,
  },
  {
    path: "/virtual-tour/:identifier",
    name: "Virtual Tour",
    component: Tour,
    props: true,
  },
  {
    path: "/mailing-list",
    name: "MailingList",
    component: MailingList,
  },
  {
    path: "/request-media-pack",
    name: "RequestMediaPack",
    component: RequestMediaPack,
    props: true,
  },
  {
    path: "/client-area",
    beforeEnter() {
      location.href = "https://portal.harrodspartnerships.com";
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    props: true,
  },
  {
    path: "/contact-confirmation",
    name: "contactConfirmation",
    component: contactConfirmation,
  },
  // Redirect old Fifth Floor URL
  {
    path: "/360-tour",
    beforeEnter() {
      location.href = "/virtual-tour/fifth-floor-pop-up"
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.matched.some((m) => m.meta.disableScroll)) return;
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;

<template>
    <div class="spotlight">
        <div class="row">
            <div class="col-12 col-lg-8">
                <h2 class="spotlight-heading">{{this.data.title}} </h2>
                <p v-html="this.body">
                </p>
                <div class="video-player">
                    <LazyVideo class="video w-100" :src="this.data.videoUrl"
                        :attrs="{ 'controls': true, 'autoplay': true, 'loop': true, 'muted': true }" />
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <img class="spotlight-img w-100" :src="this.data.imageUrl" />
            </div>
        </div>
    </div>
</template>
  
<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

export default {
    name: "Spotlight",
    props: ["data"],
    created() {
        this.body = documentToHtmlString(this.data.body);
    },
    data() {
        return {
            body: "",
        };
    },
    methods: {},
};
</script>
  
<style lang="scss" scoped>
.spotlight-img {
    width: 360px;
    height: 510px;
    object-fit: cover;
    background-position: center;

}

.video {
    width: 500px;
    margin-top: 20px;
}

.spotlight-heading {
    font-family: "Miller Banner";
    font-size: 30px;
    font-weight: 700;

    @include media (">=lg") {
        font-size: 30px;
    }
}

.spotlight {
    margin: 100px 30px 100px 30px;

    @include media(">=lg") {
        margin-left: 100px;
        margin-right: 100px;
    }

    @include media(">=xl") {
        margin-left: 220px;
        margin-right: 220px;
    }

    h2 {
        padding-bottom: 15px;
    }

    p {
        padding-bottom: 15px;
    }
}</style>
  
import httpClient from './httpClient';

const END_POINT_SUBMIT_QUESTION = '/SubmitQuestion';
const END_POINT_SUBMIT_MEDIAPACKREQUEST = '/SubmitMediaPackRequest';

const submitQuestion = (question) =>
  httpClient.post(END_POINT_SUBMIT_QUESTION, {...question});

const submitMediaPackRequest = (request) =>
  httpClient.post(END_POINT_SUBMIT_MEDIAPACKREQUEST, {...request});

export {
  submitQuestion,
  submitMediaPackRequest
}
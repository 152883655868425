<template>
    <div>
        <h1 class="ymal-title">You May Also Like...</h1>
        <ImageGrid v-bind="{ data: this.data }" />
    </div>
</template>
  
<script>
import ImageGrid from "./ImageGrid.vue";

export default {
    name: "YouMayAlsoLike",
    props: ["data"],
    components: {
        ImageGrid
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
  
<style lang="scss" scoped>
.ymal-title {
  font-size: 26px;
  text-align: center;

  @include media(">lg") {
    font-size: 40px;
  }
}

h1 {
  font-family: "Miller Banner";
  font-weight: 300;
  font-style: italic;
  font-size: 26px;
  margin-top: 22px;
  color: #212121;

  @include media(">=lg") {
    font-size: 40px;
  }
}

h2 {
  font-family: "Miller Banner";
  font-weight: 300;
  font-style: italic;
  font-size: 26px;
  margin-top: 22px;
  color: #212121;

  @include media(">=lg") {
    font-size: 30px;
  }
}
</style>